<template>
  <list-content
      v-bind:rows-data="tableData"
      v-bind:columns="tableColumns"
      v-bind:add-label="pageContent.add_label"
      v-bind:title="pageContent.title"
      v-bind:new-uri="pageContent.newUri"
      v-bind:edit-uri="pageContent.editUri"
      v-bind:meta="meta"
      v-bind:show-message="showMessage"
      v-bind:message="message"
      @handlePageChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
      @handleSearchData="performSearch"
      @handleDeleteData="handleDelete"
      v-if="is('super-admin')"
  ></list-content>
  <list-content
      v-bind:rows-data="tableData"
      v-bind:columns="tableColumns"
      v-bind:add-label="pageContent.add_label"
      v-bind:title="pageContent.title"
      v-bind:new-uri="pageContent.newUri"
      v-bind:edit-uri="pageContent.editUri"
      v-bind:meta="meta"
      v-bind:show-message="showMessage"
      v-bind:message="message"
      @handlePageChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
      @handleSearchData="performSearch"
      @handleDeleteData="handleDelete"
	  v-if="is('designer')"
  ></list-content>
<user-website-template v-if="is('realtor')"></user-website-template>
</template>

<script>
import TemplateService from "@/api/template.service";
import ListContent from "@/components/ListContent";
import UserWebsiteTemplate from "@/views/UserWebsiteTemplate";

export default {
  name: "Template",
  components: {ListContent, UserWebsiteTemplate},
  data() {
    return {
      tableColumns: [
          {'key':'name', 'label':'Template Name', 'sortable':true, 'is_image': false, 'width':''},
          {'key':'path', 'label':'Template Folder Name', 'sortable':true, 'is_image': false, 'width':''}
      ],
      pageContent: {
        'add_label': 'Add Template',
        'title': 'Template',
        'newUri': '/template/add',        
        'editUri':'/template/edit'
      },
      tableData: [],
      page: 1,
      search: '',
      meta: Object,
      showMessage: false,
      message: {message:'', messageType:'success', effect:'dark'}
    }
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    async getTableData() {
      return TemplateService.templates(this.page, this.search).then(response => {
        this.meta = response.data.meta;
        this.tableData = response.data.data;
      });
    },
    performSearch(search) {
      this.search = search.name;
      this.getTableData();
    },
    handleDelete(data) {
      let deleteRow = data.row;
      this.showMessage = false;
      if (data.indexNo >= 0) {
        return TemplateService.delete(deleteRow).then(response => {
          if(response.status === 201) {
            this.message.message = "Template deleted successfully";
            this.message.messageType = "success";
            this.message.effect = "dark";
            this.showMessage = true;
            this.getTableData();
          }
        });
      }
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getTableData();
    },
    handleSizeChange(val){
      this.getTableData();
    }
  }
}
</script>
<style lang="scss">
</style>
<style lang="scss" scoped>
</style>
