import BaseService from "./base.service";

class TemplatesService {

    templates(page = null, search = null) {
        let params = [];
        if(search) {
            params.push({'key':'q', 'value':search});
        }
        return BaseService.apiGetRequest('templates', page, params);
    }
    getTemplatesList(){
        return BaseService.apiGetRequest('auth/gettemplateslist');
    }
    getTemplate(id){
        return BaseService.apiGetRequest('auth/gettemplate/'+id);
    }
    getUserWebsite(){
        return BaseService.apiGetRequest('auth/getuserwebsite');
    }
    getTemplates(name=null) {
        return BaseService.apiGetRequest('getTemplates/'+name);
    }
    get(id) {
        return BaseService.apiGetRequest('templates/'+id);
    }
	getThemeName() {
		return BaseService.apiGetRequest('getthemename');
    }
    delete(templates) {
		return BaseService.apiPostRequest('templates/delete/', templates);
    }
    add(templates) {
        return BaseService.apiPostRequest('templates/add', templates);
    }
    setTheme(theme) {
        return BaseService.apiPostRequest('auth/theme/settheme', theme);
    }
    update(id, templates) {
        return BaseService.apiPostRequest('templates/'+id, templates);
    }
    getFilesList(templateName='default') {
        return BaseService.apiGetRequest('list-files/'+templateName);
    }
    getFilesListCss(templateName='default') {
        return BaseService.apiGetRequest('list-files-css/'+templateName);
    }
    getFilesListJs(templateName='default') {
        return BaseService.apiGetRequest('list-files-js/'+templateName);
    }
    getFilesListIcons(templateName='default') {
        return BaseService.apiGetRequest('list-files-icons/'+templateName);
    }
    saveFileContent(editFile) {
        if(!editFile.path) {
            return null
        }
        console.log(editFile)
        const formData = new FormData()
        formData.append('file', editFile.path)
        formData.append('content', editFile.content)
        formData.append('filedir', editFile.filedir)
        return BaseService.apiPostRequest('update-template-file-content', formData);
    }
    getFileContent(filePath = null) {
        if(!filePath) {
            return null
        }
        const formData = new FormData()
        formData.append('file', filePath)
        return BaseService.apiPostRequest('get-template-file-content', formData);
    }
    getFileContentCss(filePath = null) {
        if(!filePath) {
            return null
        }
        const formData = new FormData()
        formData.append('file', filePath)
        return BaseService.apiPostRequest('get-template-file-content-css', formData);
    }
    getFileContentJs(filePath = null) {
        if(!filePath) {
            return null
        }
        const formData = new FormData()
        formData.append('file', filePath)
        return BaseService.apiPostRequest('get-template-file-content-js', formData);
    }
}
export default new TemplatesService();
